<script setup>
definePageMeta({
  layout: 'moto-layout',
})
useHead({
  title: 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce',
  meta: [
    {name: 'description', content: 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź'},
    {property: 'og:title', content: 'Moto Vehis - najlepszy portal motoryzacyjny w Polsce'},
    {property: 'og:description', content: 'Śledź na bieżąco wydarzenia ze świata motoryzacji w Polsce i na świecie. Premiery, testy samochodów, trendy w motoryzacji. Tylko u nas! Sprawdź'},
    {property: 'og:type', content: 'article'},
    {property: 'og:image', content: 'https://vehis.pl/moto/assets/img/moto-vehis-logo-new.png'},
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl/moto'},
  ],
})

const motoHomepageData = reactive({
  categories: [],
  gallery: [],
  latest: [],
  most_popular: [],
  news: [],
  premieres: [],
  top: [],
  video: []
});

const loaded = ref(false);

const backend = useBackend();
const {data: collections} = await useAsyncData(async () => {
  const collections = await backend.getCollections();
  return collections.data || [];
});

await useAsyncData(async () => {
  const {data} = await backend.getMotoHomepageData();
  Object.assign(motoHomepageData, data);
  loaded.value = true;
});
</script>
<template>
  <Container no-paddings>
      <LayoutColumns>
        <LayoutMainColumn noflex>
          <MainContentHeader :left-on-mobile="true" class="text-left">Najnowsze publikacje</MainContentHeader>
          <SkeletonFeaturedPost v-show="!loaded" />
          <BlogFeaturedPost v-show="loaded" :featured="motoHomepageData.top[0]" class="mb-6" />

          <div class="grid md:grid-cols-2 gap-6 mb-6">
            <SkeletonMainCard v-show="!loaded" v-for="(item, index) in 4" :key="index" />
            <BlogMainCard v-show="loaded" :item="item" v-for="(item, index) in motoHomepageData.latest" :key="index" />
          </div>
          <div class="flex justify-center">
            <ButtonRounded href="moto/kategoria/najnowsze" class="mb-16" color="transparent" fit>Więcej najnowszych publikacji</ButtonRounded>
          </div>

        </LayoutMainColumn>
        <LayoutSideColumn class="hidden lg:block pb-4 !bg-transparent">
          <MainContentHeader>Najpopularniejsze</MainContentHeader>
          <div class="flex gap-6 flex-col">
            <SkeletonAsideCard v-show="!loaded" v-for="(item, index) in 5" :key="index" />
            <BlogAsideCard :position="'Najpopularniejsze'" v-show="loaded" :item="item" v-for="(item, index) in motoHomepageData.most_popular" :key="index" />

          </div>
          <!-- <BlogCarOfferBanner/> -->
        </LayoutSideColumn>
      </LayoutColumns>
  </Container>

  <CarDayOffers isMoto :typeDataLayer="'moto_section_2'" :custom-title="'Szukasz samochodu w leasingu? Sprawdź, co przygotował dla Ciebie VEHIS'" />

  <Container>
    <div class="flex justify-between items-center">
      <MainContentHeader class="whitespace-nowrap">Wiadomości</MainContentHeader>
      <ButtonRounded :custom-class="'whitespace-nowrap absolute lg:relative bottom-0 lg:bottom-auto left-1/2 lg:left-auto transform lg:transform-none w-full -translate-x-1/2 lg:translate-unset'" href="moto/kategoria/wiadomosci" color="transparent" fit>Więcej wiadomości</ButtonRounded>
    </div>
    <SkeletonSlider v-show="!loaded" />
    <BlogSlider v-show="loaded" :articles="motoHomepageData?.news" />
  </Container>

  <Container gray>
    <CarCollection
      :isMoto="true"
      :title="'Zobacz najtańsze samochody dostępne w leasingu VEHIS'"
      :search="collections[0].search"
      :href="collections[0].slug"
      limit="12"
    />
  </Container>

  <Container >
    <div class="flex justify-between items-center">
      <MainContentHeader>Premiery</MainContentHeader>
      <ButtonRounded :custom-class="'whitespace-nowrap absolute lg:relative bottom-0 lg:bottom-auto left-1/2 lg:left-auto transform lg:transform-none w-full -translate-x-1/2 lg:translate-unset'" href="moto/kategoria/premiery" color="transparent" fit>Więcej premier</ButtonRounded>
    </div>
    <BlogSlider v-show="loaded" :articles="motoHomepageData?.premieres" />
  </Container>

  <Container gray>
    <div class="flex justify-between items-center">
      <MainContentHeader>Galeria zdjęć</MainContentHeader>
      <ButtonRounded :custom-class="'whitespace-nowrap absolute lg:relative bottom-0 lg:bottom-auto left-1/2 lg:left-auto transform lg:transform-none w-full -translate-x-1/2 lg:translate-unset'" href="moto/kategoria/zdjecia" color="transparent" fit>Więcej galerii</ButtonRounded>
    </div>
    <SkeletonSlider v-show="!loaded" />
    <BlogSlider v-show="loaded" :articles="motoHomepageData?.gallery" :is-white-card="true" :no-aside-cards="true" />
  </Container>

  <Container>
    <div class="flex justify-between items-centesr">
      <MainContentHeader>Wideo</MainContentHeader>
      <ButtonRounded :custom-class="'whitespace-nowrap absolute lg:relative bottom-0 lg:bottom-auto left-1/2 lg:left-auto transform lg:transform-none w-full -translate-x-1/2 lg:translate-unset'" href="moto/kategoria/filmy" color="transparent" fit>Więcej wideo</ButtonRounded>
    </div>
    <SkeletonSlider v-show="!loaded" />
    <BlogSlider v-show="loaded" :articles="motoHomepageData?.video" :no-aside-cards="true" />
  </Container>
</template>
